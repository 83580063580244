import React, { useState, useEffect, useRef } from "react";
import Map, { Marker } from "react-map-gl/maplibre";

import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";

import Amenity from "../components/amenities/Amenity";
import Controls from "../components/controls/Controls";

import { Button, Col, Container, Row } from "react-bootstrap";
import pin from "../assets/images/locationpin.png";
import "./home.scss";
import Autocomplete from "../components/autocomplete/Autocomplete";
import { reversegeocodingUrl, mapStyleUrl } from "../urls/urls";
import directions from "../assets/images/get-direction.png";
import share from "../assets/images/sharebtn.png";
import cancel from "../assets/images/remove.png";
import Direction from "../components/directions/Direction";
import Panoromaview from "../components/360view/Panoromaview";
import { useMapContext } from "../components/context/Mapcontext";
import { useMap } from "react-map-gl/maplibre";
import Events from "../components/events/eventbox/Events";
import Eventcluster from "../components/events/eventclusters/Eventcluster";

const Home = () => {
  const [lng, setLng] = useState(85.32239);
  const [lat, setLat] = useState(27.677106);
  const [zoomLevel, setZoomLevel] = useState(13);

  const [srcLat, setSrcLat] = useState(null);
  const [srcLng, setSrcLng] = useState(null);
  const [dstLat, setDstLat] = useState(null);
  const [dstLng, setDstLng] = useState(null);

  // state variables for panoroma images clicked latitude and longitude
  const [clickedLat, setClickedLat] = useState(null);
  const [clickedLng, setClickedLng] = useState(null);

  const { isBooleanValue, setBooleanValue, panoOn, setPanoOnValue } =
    useMapContext();
  const mapRef = useRef(null);
  const [polygonCoordinates, setPolygonCoordinates] = useState([]);
  const { cross, setCrossValue } = useMapContext();
  const [mapLayerStyle, setMapLayerStyle] = useState({});
  const [loadPanoData, setLoadPanoData] = useState(false);
  const [error, setError] = useState("");

  // display pano lines on and off
  useEffect(() => {
    // alert(loadPanoData);
    loadPanoData ? addPanoMapTileStyle() : removePanoMapTileStyle();
  }, [loadPanoData]);

  const testingMapLoad = () => {
    setLoadPanoData(!loadPanoData);
  };

  const removePanoMapTileStyle = async () => {
    setPanoOnValue(false);
    // console.log(panoOn);
    try {
      const response = await fetch(mapStyleUrl);
      const geojson = await response.json();
      let layers = geojson.layers;
      // console.log(layers);
      let data = layers.filter((x) => x.id === "pano-layer");
      if (data.length > 0) {
        let index = layers.findIndex((layer) => layer === data[0]);
        layers.splice(index, 1);
        geojson.layer = layers;
        setMapLayerStyle(geojson);
      } else {
        console.log("Data not found in layers");
      }
    } catch (error) {
      setError("Something went wrong");
    }
  };

  const addPanoMapTileStyle = () => {
    const panoTile = {
      id: "pano-layer",
      type: "line",
      source: "gallitiles",
      "source-layer": "pano",
      layout: {
        visibility: "visible",
      },
      paint: {
        "line-color": "#409FA0",
        "line-width": {
          stops: [
            [5, 0.5],
            [16, 4],
          ],
        },
      },
    };

    let baseLayers = { ...mapLayerStyle };
    baseLayers.layers = [...baseLayers.layers, panoTile];

    setMapLayerStyle(baseLayers);
  };

  useEffect(() => {
    removePanoMapTileStyle();
  }, []);

  // useEffect(() => {
  //   const zoomDelay = setTimeout(() => {
  //     // Set zoom level to 14 after 1000ms
  //     mapRef.current?.flyTo({
  //       center: [lng, lat],
  //       zoom: 14,
  //       speed: 1,
  //       curve: 1,
  //       // essential: true,
  //       duration: 1000,
  //     });
  //   }, 500);
  //   removePanoMapTileStyle();
  //   return () => clearTimeout(zoomDelay); // Cleanup the timeout
  // }, []); // No dependencies, so it runs only once after component mounts

  const [currentZoomLevel, setCurrentZoomLevel] = useState(null);

  const { current: map } = useMap();
  useEffect(() => {
    // console.log(lng, lat, "testing");
    // let map = mapContainer.current?.getMap();
    mapRef.current?.flyTo({
      center: [lng, lat],
      zoom: zoomLevel,
      speed: 1, // Controls the speed of the flight animation
      curve: 1, // Controls the easing of the flight animation
      essential: true,
      duration: 2000,
    });
    // console.log(mapRef.current);
    // Update currentZoomLevel state when the map zoom changes

    // map.on("zoom", () => {
    // const num = mapRef.getZoom();
    // console.log(num);
    // setCurrentZoomLevel(num);
    // });
  }, [lat, lng, zoomLevel]);

  const settingZoomLevels = (lng, lat, zoomLevel) => {
    setLng(lng);
    setLat(lat);
    setZoomLevel(zoomLevel);
  };

  const [address, setAddress] = useState(""); // State variable to store the reverse geocoded address

  const reverseGeocode = async (lat, lng) => {
    const url = `${reversegeocodingUrl}&lat=${lat}&lng=${lng}`;

    try {
      const response = await fetch(url);
      const result = await response.json();
      // console.log(result.data);
      if (result.success) {
        // Store the result for the current coordinates
        result[`${lat},${lng}`] = result.data;
        return { success: true, data: result.data };
      } else {
        return { success: false, message: "Address not found", lat, lng };
      }
    } catch (error) {
      console.error("Error fetching reverse geocoding data:", error);
      return { success: false, message: "Error fetching address", lat, lng };
    }
  };

  const [toggle, setToggle] = useState(false);
  const [startAddress, setStartAddress] = useState("");
  const [endAddress, setEndAddress] = useState("");

  const { amenityWord, setAmenityValue } = useMapContext();
  const { list, setListValue } = useMapContext();

  //clearing reverse
  useEffect(() => {
    if (amenityWord !== "") {
      setToggle(false);
      setAddress("");
      setStartAddress("");
      setEndAddress("");
    }
    if (!isBooleanValue) {
      setToggle(false);
      setBooleanValue(isBooleanValue);
    }
  }, [amenityWord, isBooleanValue]);

  // Define a separate state variable to store the current zoom level

  // Update the currentZoomLevel state when the zoom changes
  const handleZoomChange = (newZoomLevel) => {
    setCurrentZoomLevel(newZoomLevel);
  };

  // const handleLatLng = async (e) => {
  //   // console.log(e);
  //   if (marker === false) {
  //     setMarker(true);
  //   }
  //   if (amenityWord !== "") {
  //     setAmenityValue("");
  //     setBooleanValue(!isBooleanValue);
  //   }
  //   if (list === false) {
  //     setListValue(true);
  //   }

  //   const { lng, lat } = e.lngLat;
  //   const result = await reverseGeocode(lat, lng);
  //   // Set the clicked latitude and longitude for panorama images
  //   setClickedLat(lat);
  //   setClickedLng(lng);
  //   settingZoomLevels(lng, lat, 18);
  //   if (toggle === true) {
  //     setToggle(true);
  //   } else {
  //     setToggle(true);
  //   }
  //   // setToggle(!toggle);
  //   setCrossValue(!cross);
  //   if (result.success) {
  //     setAddress(result.data);
  //     if (panoOn) {
  //       setPanoOnValue(panoOn);
  //     } else {
  //       setPanoOnValue(!panoOn);
  //     }

  //     // if (dstLat === null || dstLng === null) {
  //     setEndAddress(result.data.generalName);
  //     setDstLat(lat);
  //     setDstLng(lng);
  //     // console.log(result.data.pois);
  //     // }
  //   } else if (result.success && result.data.generalName === "-,") {
  //     setToggle(false);
  //     setPanoOnValue(false);
  //   } else {
  //     console.error(result.message);
  //     setAddress(result.message);
  //   }
  // };

  const handleLatLng = async (e) => {
    // console.log(e);
    if (!marker) {
      setMarker(true);
    }
    if (amenityWord !== "") {
      setAmenityValue("");
      setBooleanValue(!isBooleanValue);
    }
    if (!list) {
      setListValue(true);
    }

    const { lng, lat } = e.lngLat;
    const result = await reverseGeocode(lat, lng);
    // Set the clicked latitude and longitude for panorama images
    setClickedLat(lat);
    setClickedLng(lng);
    settingZoomLevels(lng, lat, 18);

    // Simplified toggle logic
    setToggle(true);

    setCrossValue(!cross);

    if (result.success) {
      // If the generalName is "-,", turn off toggle and panoOn, and skip setting the address
      if (result.data.generalName === "-,") {
        setToggle(false); // Turn off toggle
        setPanoOnValue(false); // Turn off panoOn
        // Do not set the address here
      } else {
        // Set the address and related fields if generalName is valid
        setAddress(result.data);
        setEndAddress(result.data.generalName);
        setDstLat(lat);
        setDstLng(lng);

        // Handle panoOn toggle logic
        if (panoOn) {
          setPanoOnValue(panoOn);
        } else {
          setPanoOnValue(!panoOn);
        }
      }
    } else {
      // Handle error scenario
      console.error(result.message);
      setAddress(result.message); // Set error message as address (optional, based on use case)
    }
  };

  const handleClear = () => {
    setToggle(!toggle);
    setSrcLat(null);
    setSrcLng(null);
    setDstLat(null);
    setDstLng(null);
    setPanoOnValue(false);
  };

  const { direction, setDirectionValue } = useMapContext();
  // const [direction, setDirection] = useState(false);

  const [marker, setMarker] = useState(true);

  const handleClick = () => {
    setDirectionValue(!direction);
    // Update the panorama view styles when the direction button is clicked
    setBooleanValue(!isBooleanValue);
    setMarker(false);
  };

  return (
    <>
      <Map
        ref={mapRef}
        // zoom={zoomLevel}
        // minZoom={10}
        // maxZoom={22}
        mapLib={maplibregl}
        initialViewState={{
          longitude: lng,
          latitude: lat,
          zoom: zoomLevel,
        }}
        style={{ width: "100%", minHeight: "100vh" }}
        mapStyle={mapLayerStyle}
        attributionControl={false}
        onClick={handleLatLng}
        onZoomChange={handleZoomChange}
      >
        {toggle && (
          <>
            <div className="reverse-geocode">
              {marker && (
                <Marker longitude={lng} latitude={lat} anchor="bottom">
                  <img src={pin} alt="pin" style={{ height: "40px" }} />
                </Marker>
              )}
              {marker && (
                <div
                  className={isBooleanValue ? "pointinfo-select" : "point-info"}
                >
                  <div className="point-info-inner">
                    <div
                      className="point-title"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <>
                        <span>
                          {address.pois
                            ? address.pois.location.split(",").slice(0, 1)
                            : address?.generalName
                                ?.split(",")
                                .slice(0, 3)
                                .join(",") ||
                              address.name ||
                              address.tole ||
                              address.place ||
                              address.municipality}
                        </span>
                        <img src={cancel} alt="close" onClick={handleClear} />
                      </>
                      {/* <Row>
                        <Col md={10}>
                          <span>
                            {address.pois
                              ? address.pois.location.split(",").slice(0, 1)
                              : address?.generalName
                                  ?.split(",")
                                  .slice(0, 3)
                                  .join(",") ||
                                address.name ||
                                address.tole ||
                                address.place ||
                                address.municipality}
                          </span>

                         
                        </Col>
                        <Col md={2}>
                          <img src={cancel} alt="close" onClick={handleClear} />
                        </Col>
                      </Row> */}
                    </div>

                    <small>
                      {address.gallicode
                        ? address.gallicode
                        : ` ${address.municipality}-${address.ward}`}
                    </small>

                    <p>
                      {lat.toPrecision(8)}, {lng.toPrecision(8)}
                    </p>
                    <hr />
                  </div>

                  <div className="point-direction">
                    <Button
                      className="direction-btn"
                      onClick={() => {
                        handleClick();
                      }}
                      // href="/directions"
                    >
                      <img src={directions} alt="get-direction" />

                      <span>Directions</span>
                    </Button>
                    <Button className="share-btn">
                      <img src={share} alt="share" />
                    </Button>
                  </div>
                </div>
              )}

              {direction && (
                <div>
                  <Direction
                    secondLat={dstLat}
                    secondLng={dstLng}
                    stop={endAddress}
                  />
                </div>
              )}
            </div>
          </>
        )}
        <Row>
          <Col sm={12} md={4} lg={4}>
            <Autocomplete settingZoomLevels={settingZoomLevels} />
          </Col>

          <Col sm={12} md={8} lg={8}>
            <Amenity />
          </Col>
        </Row>
        /
        <Row>
          <Col md={4}></Col>
          <Col md={8} style={{ position: "relative" }}>
            <Eventcluster />
          </Col>
        </Row>
        {panoOn && (
          <Panoromaview latitude={clickedLat} longitude={clickedLng} />
        )}
        <Controls testingMapLoad={testingMapLoad} />
      </Map>
    </>
  );
};

export default Home;
